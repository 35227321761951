import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import '../theme/bootstrap-grid.css'
import {
  Box,
  Card,
  Container,
  Heading,
  HeroSection,
  Image,
  Layout,
  LeadSection,
  ProductLine,
  Text,
  VideoIframe
} from '../components'
import type { ProductLineProps } from '../components/ProductLine/ProductLine'

export interface SoybeansTemplateProps {
  seoTitle: string
  metaDescription: string
  hero: {
    heading: string
    subheading: string
    text: string
    background: string
  }
  lead: {
    heading: string
    text: string
  }
  details: Array<{
    heading: string
    text: string
    icon: string
  }>
  productLine: ProductLineProps
}

const SoybeansPageTemplate: React.FC<SoybeansTemplateProps> = ({
  seoTitle,
  metaDescription,
  hero,
  lead,
  details,
  productLine
}) => {
  const [videoIframeOpen, setVideoIframeOpen] = useState(false)

  return (
    <>
      <Helmet>
        <title>Incobrasa | {seoTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <HeroSection
        {...hero}
        hasVideo
        onClickPlay={() => setVideoIframeOpen(true)}
      />
      <Box my="50">
        <Container>
          <LeadSection {...lead} />
        </Container>
      </Box>
      <Box mb="100">
        <Container>
          <Box className="row">
            {details.map(({ heading, text, icon }) => (
              <Box key={heading} className="col col-12 col-md-6" mb="medium">
                <Card>
                  <Image
                    width="50"
                    height="50"
                    mr="medium"
                    flexShrink="0"
                    mb="large"
                    src={icon}
                    alt={icon}
                  />
                  <Heading level="4" mb="medium" textTransform="uppercase">
                    {heading}
                  </Heading>
                  <Text size="small" whiteSpace="break-spaces">
                    {text}
                  </Text>
                </Card>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
      <Box my="100">
        <Container>
          <ProductLine {...productLine} />
        </Container>
      </Box>
      {videoIframeOpen && (
        <VideoIframe
          src="https://player.vimeo.com/video/351036307?autoplay=1&loop=1&autopause=0"
          onRequestClose={() => setVideoIframeOpen(false)}
        />
      )}
    </>
  )
}

interface SoybeansPageProps {
  data: {
    markdownRemark: {
      frontmatter: SoybeansTemplateProps
    }
  }
}

const SoybeansPage: React.FC<SoybeansPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout transparentNavbarOnTop>
      <SoybeansPageTemplate {...frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query SobyeansPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "soybeans" } }) {
      frontmatter {
        seoTitle
        metaDescription
        hero {
          heading
          subheading
          text
          background
        }
        lead {
          heading
          text
        }
        details {
          heading
          text
          icon
        }
        productLine {
          heading
          subheading
          items {
            heading
            subheading
            path
            image
          }
        }
      }
    }
  }
`

export { SoybeansPageTemplate }
export default SoybeansPage
